import dotenv from "dotenv";
dotenv.config();

const CONFIG = {

 BASE_URL: "https://psyfy.pro",






  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  STRIPE_PUBLIC: process.env.REACT_APP_STRIPE_PUBLIC,
};
export default CONFIG;
