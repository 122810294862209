import "../style.scss";
import { Link, useNavigate } from "react-router-dom";
//import React, { useState } from "react";
import React, { useState, useEffect, useRef } from "react";

interface HeaderProps {
  isChatbotPage?: boolean;
}

const Header: React.FC<HeaderProps> = ({ isChatbotPage = false }) => {
  const navigate = useNavigate();
  const isLoggedIn = () => !!localStorage.getItem("userEmail");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    alert("You have now logged out of the system.");

    //Redirect after logout
    navigate("/");
    setIsMenuOpen(false);
  };
  // const navbarStyle = {
  //   backgroundColor: isChatbotPage ? "#EF7B45" : "#fafafa",
  // };
//   const scrollToTop = () => {
//     window.scrollTo({
//         top: 0,
//         behavior: 'smooth'
//     });
// };
const scrollToTop = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault(); // Prevent default anchor behavior
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};


  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", closeMenu);
    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, []);


  return (
    // <nav role="navigation">
    <div id="menuToggle" ref={menuRef}>
      <input
        type="checkbox"
        checked={isMenuOpen}
        onChange={() => setIsMenuOpen(!isMenuOpen)}
      />
      <span></span>
      <span></span>
      <span></span>

      <ul id="menu" className={`${isMenuOpen ? "show" : ""}`}>
        <li className="nav-item">
          <Link
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
            to="/"
          >
            Main
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
            to="/intro"
          >
            Intro
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
            to="/research"
          >
            Research
          </Link>
        </li>
        {/* {isLoggedIn() && (
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
              to="/profile"
            >
              Profile
            </Link>
          </li>
        )} */}

        {/* {!isLoggedIn() && (
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
              to="/signup"
            >
              Sign Up
            </Link>
          </li>
        )} */}

        {!isLoggedIn() && (
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
              to="/login"
            >
              Login
            </Link>
          </li>
        )}

        {isLoggedIn() && (
          <li className="nav-item">
            <Link className="nav-link" onClick={handleLogout} to="">
              Logout
            </Link>
          </li>
        )}

      {/* {isLoggedIn() && (
            <li className="nav-item">
              <Link className="nav-link" to="/credits">
                Subscription
              </Link>
            </li>
          )} */}


        {isLoggedIn() && (
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={() => setIsMenuOpen(false)}
              to="/chatbot"
            >
              Chatbot
            </Link>
          </li>
        )}

        <li className="nav-item">
          {/* <a href="#" onClick={scrollToTop} className="nav-link">Scroll to Top</a> */}
          <a href="#" onClick={scrollToTop} className="nav-link">Scroll to Top</a>
         </li>
      </ul>
    </div>
  );
};

export default Header;
