import axios from 'axios';
import CONFIG from "../config";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Logger from "../utils/logger";
import { jwtDecode } from 'jwt-decode';


export const usePsyfyClient = () => {
  
    const navigate = useNavigate();


    const checkUserCredits = async () => {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        navigate("/login");
        return;
      }
  
      try {
        const response = await axios.post(`${CONFIG.BASE_URL}/check_credits`, { user_email: userEmail });
   
        
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        if (response.data.user_credits === 0) {
          // Handle credit-depletion logic here or by returning necessary info
          return false;
        }
        
        return true;
      } catch (error) {
        Logger.error("Error fetching user credits: ", error);
        // Return or handle 
        return false;
      }
    };

 

  const checkAndSyncSession = async (userEmail, sessionId) => {
    try {
      const postData = new FormData();
      postData.append("user_email", JSON.stringify(userEmail));
      postData.append("session_id_value", JSON.stringify(sessionId));
      const accessToken = localStorage.getItem("access_token");

      // Initialize headers object
      let headers = { "Content-Type": "multipart/form-data" };

      // Add Authorization only if accessToken exists
     if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
  
      const response = await axios({
        url: `${CONFIG.BASE_URL}/user_session`,
        method: 'POST',
       // headers: { 'Content-Type': 'multipart/form-data' },
        data: postData,
        headers: headers,
      });
  
      const data = response.data;
      if (data.session_id) {
        return {
          session_id: data.session_id,
          error: false,
          message: 'Session synced successfully.'
        };
      } else {
        return {
          session_id: null,
          error: true,
          message: 'Session not found. User might not be logged.'
        };
      }
    } catch (err) {
      Logger.debug('Error checking/syncing session:', err);
      return {
        session_id: null,
        error: true,
        message: `Error checking/syncing session: ${err.message}`
      };
    };
    
  };

  const fetchConversationHistory = async (sessionId) => {

    Logger.debug("load conversation history")
    const postData = new FormData();
    postData.append("session_id", JSON.stringify(sessionId));
    const accessToken = localStorage.getItem("access_token");

    // Initialize headers object
    let headers = { "Content-Type": "multipart/form-data" };

     // Add Authorization only if accessToken exists
     if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/conversation_history`,
        data: postData,
        headers: headers,
      });
      
      return response.data; // Return the data directly from the function

     
    } catch (error) {
      // It's often useful to throw an error further to be handled by the caller
      throw new Error(`Failed to fetch conversation history: ${error.message}`);
    }
    
  };

  const checkIfRepeatUser = async () => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/repeat_user`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      const data = response.data;
      return data.isRepeatUser;
    } catch (error) {

      Logger.debug("Error checking repeat user on primary server:");

     
    }
  };



  const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_expiration');

    // Redirect to login page
    navigate('/login');
  };



const sendBotMessage = async (data) => {
// async function sendBotMessage(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
  
    const accessToken = localStorage.getItem("access_token");
  
  
      // Check if token exists and is not expired
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000; // Current time in seconds

    // console.log("token time", decodedToken.exp)

    if (decodedToken.exp < currentTime) {
      // Token is expired, handle logout
      logoutUser();
      return Promise.reject(new Error("Token expired, user logged out"));
    } else {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  
    try {
      // First try with the primary URL
      const response = await axios.post(`${CONFIG.BASE_URL}/reply`, data, {
        headers,
      });
      Logger.debug("this is response data 22222", response.data);


      return response.data;
    } catch (error) {

      Logger.debug("authentication error", error);
      // console.log("there's error")
      // if (error.response && error.response.status === 500) {
      //   Logger.debug("Received 500 error. Calling requestSummarySurrogate...");
      //   // Call requestSummarySurrogate function on 500 error
      //   return await requestSummarySurrogate();
      // }
      // Logger.debug("Authentication or other error:", error);
      // return Promise.reject(error); // Reject with the original error for non-500 errors
    }
  }


  


  const prepareAndSendBotMessage = async ( messages,
    humanMessage,
    messageIndex,
    humanTimestamp) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");

    const aiReplies = messages
      .filter((msg) => msg.type === "bot")
      .map((msg) => msg.text);
    let humanReplies = messages
      .filter((msg) => msg.type === "user")
      .map((msg) => msg.text);
    humanReplies.push(humanMessage); // Append the latest user message
  
   
    const postData = new FormData();
    Logger.debug("this is human time stamp", humanTimestamp);
    //encrypt human turns
    postData.append("human_turns", JSON.stringify(humanReplies));
    postData.append("ai_turns", JSON.stringify(aiReplies));
    postData.append("human_timestamp", JSON.stringify(humanTimestamp));
    postData.append("api_key_value", JSON.stringify("aurora"));
    postData.append("session_id_value", JSON.stringify(sessionId));
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("user_correction", JSON.stringify(null));
    postData.append("message_id_db", JSON.stringify(messageIndex));
    
  
    return sendBotMessage(postData);
  }

  const prepareAndSendBotMessageInitial = async (messageIndex) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");

  
    const postData = new FormData();
    postData.append("api_key_value", JSON.stringify("aurora"));
    postData.append("session_id_value", JSON.stringify(sessionId));
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("user_correction", JSON.stringify(null));
    postData.append("message_id_db", JSON.stringify(messageIndex));
    
  
    return sendBotMessage(postData);
  }
  
  
  const sendRating = async ( ratedMessage,
    ratingType,
    messageId,
    sessionId) => {
    try {
      const postData = new FormData();
  
      // Add condition for up or down rating directly here
      postData.append("user_rating_down", JSON.stringify(ratingType === "down"));
      postData.append("user_rating_up", JSON.stringify(ratingType === "up"));
      postData.append("rating_id", JSON.stringify(messageId));
      postData.append("session_id_value", JSON.stringify(sessionId));
  
      const response = await axios.post(`${CONFIG.BASE_URL}/rating`, postData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      // If necessary, update local state or perform actions based on server's response
      Logger.debug("Rating submitted successfully", response.data);
    } catch (error) {
      Logger.error("Error sending rating:", error);
    }
  }
  
  const requestSummarySurrogate = async () => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");
  
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("session_id_value", JSON.stringify(sessionId));
    const accessToken = localStorage.getItem("access_token");

     // Initialize headers object
    let headers = { "Content-Type": "multipart/form-data" };

    // Add Authorization only if accessToken exists
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/update_memory`,
        data: postData,
        headers: headers,
       // headers: { "Content-Type": "multipart/form-data" },
      });

  
      Logger.debug("Summary request response:", response.data);
      return response.data;
    } catch (error) {
      Logger.debug("Primary server error. Trying backup server:", error);
    }
  };

  const useWaitlistStatus = async (userEmail) => {

    const [isOnWaitlist, setIsOnWaitlist] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const checkUserWaitlistStatus = async () => {
        try {
          setLoading(true); // Start loading
          const response = await axios.post(
            `${CONFIG.BASE_URL}/is_user_on_waitlist`,
            { userEmail: userEmail }
          );
          setIsOnWaitlist(response.data.onWaitlist); // Update the waitlist status
        } catch (err) {
          Logger.debug("Primary server failed", err);
        } finally {
          setLoading(false); // Finish loading regardless of the result
        }
      };
  
      if (userEmail) {
        checkUserWaitlistStatus(); // Only run the check if userEmail is provided
      }
    }, [userEmail]); // The hook depends on the userEmail value
  
    return { isOnWaitlist, loading, error }; // Return the necessary states
  };

  const loginUser = async (clientEmail, clientPassword) => {
    const postData = new FormData();
    postData.append("clientEmail_login", clientEmail);
    postData.append("user_pw", clientPassword);
    
    try {
        const response = await axios.post(`${CONFIG.BASE_URL}/login_submit`, postData);
        return response;
    } catch (error) {
        Logger.debug("Primary server failed");
        throw error
    }
};

const checkUserIP = async (user) => {
  try {
      const response = await axios.post(`${CONFIG.BASE_URL}/check_ip`, { user });
      if (response.status !== 200) {
          throw new Error(`HTTP Status: ${response.status}`);
      }
      return response.data;
  } catch (error) {
      Logger.debug("Error checking user IP:", error);
      throw error;  // Rethrowing the error to be handled by caller
  }
};



const signupUser = async (postData) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/signup_submit`, postData);


    return response.data;  // Return the successful data

  } catch (error) {
    // On primary error try the backup URL
    Logger.debug("signing up", error);
    throw error; 
  }
};


const signupUserTest = async (postData) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/signup_submit_test`, postData);
    return response.data;  // Return the successful data
  } catch (error) {
    // On primary error try the backup URL
    Logger.debug("signing up", error);
    throw error; 
  }
};


const requestPasswordReset = async (email) => {
  try {
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(email));

    const response = await axios.post(`${CONFIG.BASE_URL}/request_reset`, postData);
    return response;
  } catch (error) {
    // Optionally handle errors or rethrow them
    Logger.error("Error in requestPasswordReset:", error);
    throw error;
  }
};

const requestRegistrationCode = async (email) => {
  const postData = new FormData();
  postData.append("user_email", JSON.stringify(email));

  const response = await axios({
    method: "post",
    url: `${CONFIG.BASE_URL}/request_code`,
    data: postData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const fetchUserData = async (email) => {
  const postData = new FormData();
  postData.append("userEmail", JSON.stringify(email));

  try {
    const response = await axios({
      method: "post",
      url: `${CONFIG.BASE_URL}/get_user_info`,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    // Handle any additional data manipulation or formatting here if necessary
    return response.data;
  } catch (error) {
    // Error handling, might want to pass the error up or handle it according to your needs
    throw new Error(`Failed to fetch user data: ${error.message}`);
  }
};

const reasonMappings = {
  tooManyEmails: 1,
  notInterested: 2,
  contentNotUseful: 3,
  tooExpensive: 4,
  technicalIssues: 5,
  foundAlternative: 6,
  privacyConcerns: 7,
  other: 8  // Assuming 'other' is mapped to 8
};


const handleUnsubscribe = async (userEmail, reasons) => {
  try {
      const postData = new FormData();
      postData.append("userEmail", JSON.stringify(userEmail));
      
      
      // Get the reason number from the reasonMappings
       // Determine which reason is selected
      let selectedReasonNumbers = [];
        Object.keys(reasons).forEach(key => {
            if (reasons[key] === true && reasonMappings[key]) {
                selectedReasonNumbers.push(reasonMappings[key]);
            }
        });

      
      postData.append("reasons", JSON.stringify(selectedReasonNumbers));  

      if (reasons.otherCheckbox && reasons.otherText.trim() !== '') {
        postData.append("otherReason", JSON.stringify(reasons.otherText)); 
    }



      const response = await axios({
          url: `${CONFIG.BASE_URL}/unsubscribe`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: postData,
      });

      alert(response.data.message); // Display the backend message
  } catch (error) {
      alert('Error unsubscribing, please try again.');
      Logger.error('Unsubscribe error:', error);
  }
};



const handlePayment = async (selectedTab) => {
  const userEmail = localStorage.getItem("userEmail") || "";
  const paymentMethodId = selectedTab.paymentMethodId; // Assuming this is fetched/stored elsewhere
  const amount = parseInt(selectedTab.price.replace('$', '')) * 100; // Convert the price to cen


  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));
  postData.append("paymentMethodId", paymentMethodId);
  postData.append("amount", amount.toString());
  postData.append("credit_plan", selectedTab.plan);


  const endpoint = `${CONFIG.BASE_URL}/payment`;

  try {
    const { data } = await axios({
      method: "post",
      url: endpoint,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" }
    });
   Logger.debug('Payment successful:', data);
    if (data.status === 'success') {
      navigate("/paymentsuccess", { state: { selectedTab } }); // Navigate to success page
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Logger.error('Payment failed:', error.response?.data || 'No response data');
    } else if (error instanceof Error) {
      Logger.error('Payment failed:', error.message);
    } else {
      Logger.error('Payment failed with unknown error type', error);
    }
  }
};



const fetchSubscription = async (userEmail, setSelectedTab, setAutoRenew) => {
  try {
    const response = await axios({
      url: `${CONFIG.BASE_URL}/get_subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: {
        user_email: userEmail // Send as JSON, no need to stringify manually
      },
    });

    if (response.data.isSubscribed) {
      const backendPlan = response.data.plan; // Plan from the backend
 
      // Map backend plan name to frontend plan names
      let mappedPlan = backendPlan;
      if (backendPlan === "ai.psyfy.basic") {
        mappedPlan = "Basic";
      } else if (backendPlan === "ai.psyfy.plus") {
        mappedPlan = "Plus";
      } else if (backendPlan === "ai.psyfy.premium") {
        mappedPlan = "Premium";
      }  else if (backendPlan === "ai.psyfy.free") {
        mappedPlan = "Free";
      }

      // Update the selected tab state based on the mapped plan
      setSelectedTab(prev => ({
        ...prev,
        plan: mappedPlan, // Set plan to the current plan
        currentPlan: mappedPlan, // Use the mapped plan here
        isSubscribed: true,
        isNewPlanSelected: false, // Ensure the user hasn't selected a new plan yet
      }));

      if (response.data.autoRenew_website !== undefined) {
        setAutoRenew(response.data.autoRenew_website);
      }
    } else {
      setSelectedTab(prev => ({
        ...prev,
        currentPlan: 'Not subscribed',
        isSubscribed: false,
        isNewPlanSelected: false, // Reset to default state
      }));
      setAutoRenew(false);

    }
  } catch (error) {
    Logger.error('Failed to fetch subscription:', error);
  }
};
const fetchUserSubscription_ios = async (userEmail) => {
  //check whether user subscribe the plan via ios
  try {
    const response = await axios({
      url: `${CONFIG.BASE_URL}/get_subscription_ios`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: { user_email: userEmail },
    });

    return response.data;
  } catch (error) {
    Logger.error('Failed to fetch subscription:', error);
    throw error;
  }
};


const fetchUserCredits = async () => {
  const userEmail = localStorage.getItem("userEmail") || "";

  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));

  if (!userEmail) {
    Logger.error("User email not found.");
    return null;  // Ensure we return null if there's no email.
  }

  try {
     const response = await fetch(`${CONFIG.BASE_URL}/check_credits`, {
        method: "POST",
        body: postData,
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json(); // Parsing the JSON response body
    return data.user_credits || null;  // Safely return user_credits or null if undefined
  
    }  catch (error) {
      Logger.error("Error fetching user credits:", error);
      return null;  // Return null in case of an error to handle it gracefully
    }
  };


  const handleLanguageChange = async (selectedLanguage, setUserLanguage) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("language", JSON.stringify(selectedLanguage));

    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/set_language`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      Logger.debug("Language set successfully:", response.data);
      setUserLanguage(selectedLanguage); // Update language state in the component
    } catch (error) {
      Logger.debug("Error setting language:", error);
    }
};


const sendLanguageToBackend = async (language) => {
  try {
    const userEmail = localStorage.getItem('userEmail');
    // const language = localStorage.getItem('language');
    // console.log('sending language to backend', language)

    if (userEmail) {
      const response = await axios.post(`${CONFIG.BASE_URL}/update_language`, {
        email: userEmail,
        language: language,
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // console.log('Language sent to backend successfully:', language);
      return true;
    } else {
      console.error('No user email found in AsyncStorage');
      return false;
    }
  } catch (error) {
    console.error('Failed to send language to backend:', error);
    return false;
  }
};


const fetchUserLanguageFromBackend = async () => {
  //fetch language from database
  try {
    const userEmail = localStorage.getItem('userEmail'); 
    if (userEmail) {
      const response = await axios.post(`${CONFIG.BASE_URL}/get_language`, {
        email: userEmail,
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.data.language || 'english'; // Return the language or default to English
    } else {
      console.error('No user email found in AsyncStorage');
      return 'english'; // Default to English if no email is found
    }
  } catch (error) {
    console.error('Failed to fetch language from backend:', error);
    // return 'english'; 
    return null; // Return null to indicate an error occurred
  }
};
//text to speech original
const getAudioFromBackendBasic = async (text, languageCode, voiceToUse) => {
  try {
    const accessToken = localStorage.getItem('access_token');
    const userEmail = localStorage.getItem('userEmail'); // Retrieve user email

    const headers = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token is expired, handle logout
        logoutUser();
        return Promise.reject(new Error('Token expired, user logged out'));
      } else {
        // Token is valid, add it to the Authorization header
        headers.Authorization = `Bearer ${accessToken}`;
      }
    } else {
      return Promise.reject(new Error('No access token available'));
    }

    // Send the request with the Authorization header
    const response = await fetch(`${CONFIG.BASE_URL}/get_audio_basic`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        text,
        language_code: languageCode,
        user_email: userEmail, // Include user email in the request body
        voice_to_use: voiceToUse,
      }),
    });



    if (!response.ok) {
      throw new Error('Failed to get audio from backend');
    }

    const responseJson = await response.json();
    return responseJson.audioContent; // Return the audio content
  } catch (error) {
    console.error('Error fetching audio from backend:', error);
    throw error;
  }
};



// function isValidBase64(str) {
//   try {
//     return btoa(atob(str)) === str;
//   } catch (e) {
//     return false;
//   }
// }

// function sanitizeBase64(str) {
//   return str.replace(/[^A-Za-z0-9+/=]/g, '');
// }

//buffering queue
// const chunkQueue: AudioBuffer[] = [];
// let isPlaying = false;


//for streaming
// const getAudioFromBackend = async (
//   text: string,
//   languageCode: string,
//   onChunk: (chunk: Uint8Array) => void
// ) 
const getAudioFromBackend = async function (
  text,
  languageCode,
  onChunk
) {
  try {
    const accessToken = localStorage.getItem('access_token');
    const userEmail = localStorage.getItem('userEmail');

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      const decodedToken: any = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        logoutUser();
        throw new Error('Token expired, user logged out');
      } else {
        headers.Authorization = `Bearer ${accessToken}`;
      }
    } else {
      throw new Error('No access token available');
    }

    const response = await fetch(`${CONFIG.BASE_URL}/get_audio`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        text,
        language_code: languageCode,
        user_email: userEmail,
      }),
    });

    if (!response.body) {
      throw new Error('No response body from the backend');
    }

    const reader = response.body?.getReader();

    if (onChunk) {
      // If onChunk is provided, read chunks and pass them to the callback
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        if (value) {
          const chunkBytes = new Uint8Array(value.buffer);
          onChunk(chunkBytes);
        }
      }
    } else {
      // If no onChunk is provided, handle playback directly
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const sampleRate = 24000; // Adjust sample rate as per your backend specs

      // Chunk queue and playback control
      const chunkQueue: AudioBuffer[] = [];
      let isPlaying = false;

      const playNextChunk = () => {
        if (chunkQueue.length === 0) {
          // No more chunks to play
          return;
        }

        const buffer = chunkQueue.shift();

        if (!buffer) {
          console.error('No buffer available for playback.');
          return;
        }

        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start();

        source.onended = () => {
          playNextChunk(); // When one finishes, start the next
        };

        isPlaying = true;
      };

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        if (value) {
          const chunkBytes = new Uint8Array(value.buffer);

          // Decode PCM to WAV format dynamically
          const wavData = pcmToWav(chunkBytes, sampleRate, 1);

          // Decode the WAV into an AudioBuffer and enqueue it
          const buffer = await audioContext.decodeAudioData(wavData.buffer);
          chunkQueue.push(buffer);

          // If not currently playing, start playback
          if (!isPlaying) {
            playNextChunk();
          }
        }
      }
    }
  } catch (error) {
    console.error('Error fetching audio stream from backend:', error);
    throw error;
  }
};


function pcmToWav(pcmData: Uint8Array, sampleRate: number = 16000, numChannels: number = 1): Uint8Array {
  const byteRate = sampleRate * numChannels * 2; // 16-bit = 2 bytes per sample
  const blockAlign = numChannels * 2;
  const wavHeader = new ArrayBuffer(44);
  const view = new DataView(wavHeader);

  // RIFF chunk descriptor
  writeString(view, 0, 'RIFF'); // ChunkID
  view.setUint32(4, 36 + pcmData.length, true); // ChunkSize
  writeString(view, 8, 'WAVE'); // Format

  // "fmt " sub-chunk
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true); // Subchunk1Size (PCM)
  view.setUint16(20, 1, true);  // AudioFormat (PCM = 1)
  view.setUint16(22, numChannels, true); // NumChannels
  view.setUint32(24, sampleRate, true);  // SampleRate
  view.setUint32(28, byteRate, true);    // ByteRate
  view.setUint16(32, blockAlign, true);  // BlockAlign
  view.setUint16(34, 16, true); // BitsPerSample

  // "data" sub-chunk
  writeString(view, 36, 'data');
  view.setUint32(40, pcmData.length, true);

  function writeString(view: DataView, offset: number, str: string) {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
  }

  // Combine header and PCM data
  const wavBuffer = new Uint8Array(44 + pcmData.length);
  wavBuffer.set(new Uint8Array(wavHeader), 0);
  wavBuffer.set(pcmData, 44);
  return wavBuffer;
}





function writeString(view: DataView, offset: number, str: string) {
  for (let i = 0; i < str.length; i++) {
    view.setUint8(offset + i, str.charCodeAt(i));
  }
}

//speech to text
const sendAudioToBackend = async (audioBlob) => {
  const formData = new FormData();
  const userEmail = localStorage.getItem('userEmail');
   // Generate a unique filename using the user's email
  const emailWithoutSpecialChars = userEmail.replace(/[^\w\s]/gi, ''); // Remove special characters from email
  const audioFileName = `voice_text_t_speech_${emailWithoutSpecialChars}.m4a`;

  formData.append('audio', audioBlob, audioFileName);
  formData.append('user_email', userEmail); 

  // Retrieve the selected language from localStorage
  const selectedLanguage = localStorage.getItem('language') || 'english';
  formData.append('language', selectedLanguage);

 

  try {
    const accessToken = localStorage.getItem('access_token');
    const headers = {};

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token is expired, handle logout
        logoutUser();
        return Promise.reject(new Error('Token expired, user logged out'));
      } else {
        // Token is valid, add it to the Authorization header
        headers.Authorization = `Bearer ${accessToken}`;
      }
    } else {
      return Promise.reject(new Error('No access token available'));
    }


    // Send the request with the Authorization header
    const response = await fetch(`${CONFIG.BASE_URL}/transcribe`, {
      method: 'POST',
      headers: headers, // Add the headers here
      body: formData,
    });

    if (response.status === 400) {
      // Handle short speech case
      return "We didn't get your speech. Please say it again.";
    }

    const data = await response.json();


    return data.transcription;
  } catch (error) {
    console.error('Error transcribing audio', error);
    // return Promise.reject(new Error(error.message || 'Failed to transcribe audio.'));
  }


  
};

const submitSupportRequest = async (email, message) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/support`, {
      email,
      message
    });

    if (response.status === 200) {
      return "Support request submitted successfully!";
    } else {
      return "Failed to submit support request.";
    }
  } catch (error) {
    console.error("Error submitting support request:", error);
    return "Error submitting support request. Please try again later.";
  }
};
const deleteAccount = async (accessToken) => {
  try {
    const response = await fetch(`${CONFIG.BASE_URL}/cancel_account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Return the response data
  } catch (error) {
    console.error("Error deleting account:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

//count conversation turns
const fetchUserConversationTurns = async (email) => {

  const accessToken = localStorage.getItem('access_token');
  try {
    const response = await fetch(`${CONFIG.BASE_URL}/get_conversation_turns`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Return the response data
  } catch (error) {
    console.error('Error in fetchUserConversationTurns:', error);
    throw error;
  }
};

const getPersonalityProfile = async () => {
  console.log('trying to get personality profile')

  const accessToken = localStorage.getItem('access_token');

  try {
    const response = await fetch(`${CONFIG.BASE_URL}/get_personality`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log("this is personality", response)

    if (!response.ok) {
      const errorText = await response.text();
      if (errorText.includes('No personality profile stored for user')) {
        await requestSummarySurrogate();
        return await getPersonalityProfile(); // Retry the function after surrogate
      } else {
        throw new Error('Failed to fetch personality profile');
      }
    }

    return await response.json(); // Assuming the backend returns a JSON response with bigFiveScores and confidenceScore
  } catch (error) {
    Logger.error('Error in getPersonalityProfile:', error);
    throw error; // Re-throw the error for further handling if needed
  }
};


let surrogateCallCount = 0; // Initialize counter outside the function

const getMood = async () => {
  const accessToken = localStorage.getItem('access_token');
  const userEmail = localStorage.getItem('userEmail'); // Ensure this is correctly stored

  try {
    const response = await fetch(`${CONFIG.BASE_URL}/get_mood`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_email: userEmail }), // Include user_email in request body
    });

    if (!response.ok) {
      const errorResponse = await response.json(); // Parse error response as JSON


      if (
        response.status === 400 &&
        errorResponse.error.includes('No mood profile stored for user')
      ) {
        if (surrogateCallCount < 2) {
          surrogateCallCount++; // Increment the counter

          await requestSummarySurrogate();

          return await getMood(); // Retry the function after surrogate
        } else {
          throw new Error('Max surrogate requests reached. Cannot fetch mood profile.');
        }

      } else {
        throw new Error(`Failed to fetch mood profile: ${response.status} - ${errorResponse.error}`);
      }
    }

    const data = await response.json();
    return data; // Assuming the backend returns a JSON response
  } catch (error) {
    console.error('Error in getMood:', error);
    throw error; // Re-throw the error for further handling if needed
  }
};

const sendVoiceSelectionToBackend = async (voiceId, type) => {
  const userEmail = localStorage.getItem("userEmail");
  if (!userEmail) {
    alert("User email not found. Please log in.");
    return;
  }

  try {
    const response = await fetch(`${CONFIG.BASE_URL}/select_voice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userEmail,
        voiceId,
        type,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      alert("Voice selection updated successfully!");
    } else {
      alert(`Error: ${data.message}`);
    }
  } catch (error) {
    alert("There was an issue updating your voice selection. Please try again.");
  }
};

// fetch the voices that people selected 
const fetchVoices = async () => {
  const userEmail = localStorage.getItem("userEmail");
  try {
    const response = await fetch(`${CONFIG.BASE_URL}/get_voices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userEmail }), // Include the email in the request body
    });

    if (!response.ok) {
      throw new Error("Failed to fetch voices");
    }

    const voices = await response.json();

    return voices;
  } catch (error) {
    console.error("Error fetching voices:", error);
    return [];
  }
};

const sendSessionIdToBackend = async (userEmail, sessionId) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/record_sessionid`, {
      email: userEmail,
      sessionId: sessionId,
    });

    if (response.status === 200) {

    } else {
      console.error("Failed to update session ID. Response:", response);
    }
  } catch (error) {
    console.error("Error updating session ID:", error);
  }
};


return { checkUserCredits, checkAndSyncSession, fetchConversationHistory, checkIfRepeatUser, sendBotMessage, prepareAndSendBotMessage, useWaitlistStatus, sendRating, requestSummarySurrogate, loginUser, checkUserIP, signupUser, requestPasswordReset, signupUserTest, requestRegistrationCode, fetchUserData, handleUnsubscribe, handlePayment, fetchSubscription, prepareAndSendBotMessageInitial, fetchUserCredits, handleLanguageChange, sendLanguageToBackend, fetchUserLanguageFromBackend, getAudioFromBackend, getAudioFromBackendBasic, sendAudioToBackend, fetchUserSubscription_ios, submitSupportRequest, deleteAccount, fetchUserConversationTurns, getPersonalityProfile, getMood, sendVoiceSelectionToBackend, fetchVoices, sendSessionIdToBackend};


 
}
