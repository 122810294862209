// useVoiceMode.tsx
import { useState, useRef, useEffect } from 'react';
import {usePsyfyClient} from "./psyfyClient";

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

const audioContext = new (window.AudioContext || window.webkitAudioContext!)();


export const useVoiceMode = (onVoiceMessage: (message: string) => void) => {
  const [isListening, setIsListening] = useState(false); // State to track if the system is currently listening to the user's voice
  const [transcribedText, setTranscribedText] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);  // State to track if user is currently speaking
  const isSpeakingRef = useRef(isSpeaking);
  const [volume, setVolume] = useState(0); // Volume level for visualization
  const audioContextRef = useRef<AudioContext | null>(null);  //  Reference to the audio context for volume analysis
  const analyserRef = useRef<AnalyserNode | null>(null);  // Reference to the analyser node for volume analysis
  const mediaStreamRef = useRef<MediaStream | null>(null);  // Reference to the media stream from the user's microphone
  const speakingTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  // const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);  // Reference to the media recorder for recording audio
  const audioChunksRef = useRef<Blob[]>([]); // Reference to the audio context for volume analysis
  const [isBotSpeaking, setIsBotSpeaking] = useState(false);
  const isBotSpeakingRef = useRef(isBotSpeaking);
  const speechStartTimeRef = useRef<number | null>(null);
  const MIN_SPEECH_DURATION = 3000; // Minimum speech duration (in ms) before processing
  const [isVoiceModeEnabled, setIsVoiceModeEnabled] = useState(false);
  const isVoiceModeEnabledRef = useRef(isVoiceModeEnabled);
  const { getAudioFromBackend, sendAudioToBackend, fetchVoices, getAudioFromBackendBasic, fetchUserLanguageFromBackend} = usePsyfyClient();
  const [isSilenceDetectionEnabled, setIsSilenceDetectionEnabled] = useState(false);
  const isSilenceDetectionEnabledRef = useRef(isSilenceDetectionEnabled);
  const isVolumeMeterRunningRef = useRef(false);

  const [currentMode, setCurrentMode] = useState<'voice' | 'voiceOnly' | null>(null);
  const currentModeRef = useRef(currentMode);

  const [selectedVoice, setSelectedVoice] = useState<string | null>(null);




  const retrieveSelectedVoice = async () => {
    try {
      const voices = await fetchVoices();
     // console.log("this is voices", voices);
  
      if (voices && voices.id) {
        setSelectedVoice(voices.id); // Use the retrieved voice ID if available
      } else {
       // console.log("No voice found, fetching user language from backend...");
        const userLanguage = await fetchUserLanguageFromBackend();
  
        if (userLanguage) {
          // Set default voice based on language
          const defaultVoice =
            userLanguage.toLowerCase() === "chinese"
              ? "cmn-CN-Standard-A"
              : "en-US-Standard-C";
          setSelectedVoice(defaultVoice);
         // console.log(`Default voice set to: ${defaultVoice}`);
        } else {
          console.error("Failed to fetch user language. Defaulting to English voice.");
          setSelectedVoice("en-US-Standard-C"); // Fallback to English if no language is retrieved
        }
      }
    } catch (error) {
      console.error("Error retrieving selected voice:", error);
  
      // Fallback to English default if an error occurs
      setSelectedVoice("en-US-Standard-C");
    }
  };
  
  // Fetch user language from backend

  // Use the updated function
  useEffect(() => {
    retrieveSelectedVoice();
  }, []);
// const retrieveSelectedVoice = async () => {
//     try {
//       const voices = await fetchVoices();
//       console.log("this is voices", voices)
//       setSelectedVoice(voices.id)
   
//     } catch (error) {
//       console.error('Error retrieving selected voice:', error);
//     }
//   };

//   useEffect(() => {
//     retrieveSelectedVoice();
//   }, []);



const setCurrentModeAndRef = (value: 'voice' | 'voiceOnly' | null) => {
  setCurrentMode(value);
  currentModeRef.current = value;
};

  const setIsSilenceDetectionEnabledAndRef = (value: boolean) => {
    setIsSilenceDetectionEnabled(value);
    isSilenceDetectionEnabledRef.current = value;
  };
  
  const setIsSpeakingAndRef = (value: boolean) => {
    setIsSpeaking(value);
    isSpeakingRef.current = value;
  };

  const setIsVoiceModeEnabledAndRef = (value: boolean) => {
    setIsVoiceModeEnabled(value);
    isVoiceModeEnabledRef.current = value;
  };

  const setIsBotSpeakingAndRef = (value:boolean) => {
    setIsBotSpeaking(value);
    isBotSpeakingRef.current = value;
  };
  

  // const [manualStop, setManualStop] = useState(false);
  const startVoiceMode = () => {
    // setIsVoiceModeEnabled(true);
    setCurrentModeAndRef('voice');
    setIsVoiceModeEnabledAndRef(true);
    if (!isBotSpeaking) {
      startListening();
    }
  };

  const startVoiceOnlyMode = () => {
    setCurrentModeAndRef('voiceOnly');
    if (!isBotSpeaking) {
      startListeningVoiceOnly();
    }
  };

  const stopVoiceMode = () => {
    // setIsVoiceModeEnabled(false);
    setCurrentModeAndRef(null);
    setIsVoiceModeEnabledAndRef(false);
    if (isListening) {
      stopListening();
    }
  };

  const startListeningVoiceOnly = async () => {

    if (isBotSpeakingRef.current) {
      console.error('Cannot start voice-only listening: Bot is speaking');
      return;
    }
  

    setIsSilenceDetectionEnabledAndRef(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorderRef.current = mediaRecorder;
  
      audioChunksRef.current = [];
  
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
  
      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        let transcription = await sendAudioToBackend(audioBlob); // Send audio to backend for transcription
        if (transcription) {

          transcription = transcription.replace(/\*/g, '');
          
          setTranscribedText(transcription); // Show transcription in input area
        }
  
        // Stop volume meter and update isListening state
        stopVolumeMeter();
        setIsListening(false);
  
        // Stop the media stream
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }
      };
  
      mediaRecorder.start();
      setIsListening(true); // Set isListening to true when recording starts
      startVolumeMeter(stream); // Start the volume meter for visualization
  
      // Store the media stream for later cleanup
      mediaStreamRef.current = stream;
  
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  
  


  // Function to start listening to the user's voice
  const startListening = async () => {
    // setManualStop(false); // Reset manualStop when starting to listen
    if (isBotSpeakingRef.current) {
      console.error('Cannot start voice-only listening: Bot is speaking');
      return;
    }
  
   

   // console.log('start listening');
    // setIsSilenceDetectionEnabledAndRef(true);
    setIsSilenceDetectionEnabledAndRef(true);
    
    // Check if the browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia not supported');
      return;
    }

    try {
       // Request access to the user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
     //('start stream');
      mediaStreamRef.current = stream;

      const options = { mimeType: 'audio/webm' };
      const mediaRecorder = new MediaRecorder(stream, options); // Create a new MediaRecorder instance
      mediaRecorderRef.current = mediaRecorder; // Store the media recorder in a ref
 
      audioChunksRef.current = []; // Initialize the audio chunks array
     
      // Event handler for when data is available from the media recorder
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
         // console.log('ondataavailable: received data');
          audioChunksRef.current.push(event.data);
        } else {
          console.error('ondataavailable: empty data');
        }
      };
      
      // Event handler for when the media recorder starts
      mediaRecorder.onstart = () => {
        speechStartTimeRef.current = Date.now();
      };

      mediaRecorder.onstop = async () => {
        console.error('mediaRecorder.onstop called');

        // Calculate the speech duration
        const speechEndTime = Date.now();
        const speechDuration = speechEndTime - (speechStartTimeRef.current || speechEndTime);
        
          // Check if the speech duration is long enough and if there are audio chunks to process
        if (speechDuration >= MIN_SPEECH_DURATION && audioChunksRef.current.length > 0) {
          // setIsProcessing(true);
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          //console.log('Audio Blob:', audioBlob);
          audioChunksRef.current = [];

          let transcription = await sendAudioToBackend(audioBlob);
        // console.log('Transcription result:', transcription);

          // Only send the message if the transcription contains more than one word
          if (transcription && transcription.trim().length > 1) {
            onVoiceMessage(transcription);
            setTranscribedText(transcription);
          } else {
           // console.log('Transcription too short or silent, not sending to onVoiceMessage');

             // Add a bot message indicating the issue
            onVoiceMessage("We didn't get your speech. Please say it again.");
          }

          // setIsProcessing(false);
        } else {
          //console.log('Speech duration too short or no audio chunks to process');
           // Add a bot message indicating the issue
          onVoiceMessage("We didn't get your speech. Please say it again.");
          stopVoiceMode(); 
        }

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current = null;
        }
        stopVolumeMeter();
        
        // Set isListening to false after processing
        setIsListening(false);
      };
     
      // start record after successfully obtaining the user's microphone stream
      mediaRecorder.start();
      setIsListening(true); //bot is listening to the speaker

      // Ensure mediaRecorderRef.current is set before starting volume meter
      startVolumeMeter(stream);
    } catch (error) {
      console.error('Error accessing microphone', error);
    }
  };
 
    // Function to stop listening to the user's voice
  const stopListening = () => {
    if (!mediaRecorderRef.current) {
      // If mediaRecorderRef.current is null, we are already not listening
      return;
    }

   // console.log('stopListening called');
    // setManualStop(true);
    mediaRecorderRef.current.stop();
    setIsListening(false); // Set isListening to false when stopping

     if (currentModeRef.current === 'voiceOnly') {
        setCurrentModeAndRef(null);
        setIsVoiceModeEnabledAndRef(false);
       }
  };


 
   // Function to start the volume meter for visualizing the audio input
   const startVolumeMeter = (stream: MediaStream) => {
    // Check if the AudioContext is supported by the browser
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    audioContextRef.current = new AudioContext();
  
    if (audioContextRef.current) {
      const source = audioContextRef.current.createMediaStreamSource(stream); // Connect the audio stream to the audio context
      const analyser = audioContextRef.current.createAnalyser(); // Create an analyser for volume level analysis
      analyser.fftSize = 2048; // Set the Fast Fourier Transform size to analyze the frequency data
      source.connect(analyser); // Connect the audio source to the analyser
      analyserRef.current = analyser;
  
      const dataArray = new Uint8Array(analyser.frequencyBinCount); // Array to hold frequency data for analysis
      
      isVolumeMeterRunningRef.current = true;

      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray); // Fill dataArray with frequency data from the analyser
        let values = 0;
  
        // Calculate the average volume from the frequency data
        for (let i = 0; i < dataArray.length; i++) {
          values += dataArray[i];
        }
        const average = values / dataArray.length; // Average volume level
        setVolume(average); // Update the state with the current volume level for visual feedback

        if (isBotSpeakingRef.current) {
          // Bot is speaking, do not process silence detection
          requestAnimationFrame(updateVolume);
          return;
        }

        if (isSilenceDetectionEnabledRef.current) {
          // console.log("detect sound", average)
          if (average > 10) {
     
            if (!isSpeakingRef.current) {
              setIsSpeakingAndRef(true);
            }
            if (speakingTimeoutRef.current) {
              clearTimeout(speakingTimeoutRef.current);
              speakingTimeoutRef.current = null;
            }
          } else {
            if (!speakingTimeoutRef.current) {
              speakingTimeoutRef.current = setTimeout(() => {
                if (isSpeakingRef.current) {
                  setIsSpeakingAndRef(false);
                }
                stopListening();
                speakingTimeoutRef.current = null;
              }, 3000);
  
            }
          }
        } else {
          // When silence detection is disabled (for voice-only mode)
          if (average > 10) {
            // User is speaking, volume is high enough
            if (!isSpeaking) {
              setIsSpeakingAndRef(true);
              // setIsSpeaking(true); // Update speaking state to true
            }
          } else {
            // Silence detected, volume is low, but do not stop listening
            if (isSpeaking) {
              setIsSpeakingAndRef(false);
              // setIsSpeaking(false); // User has stopped speaking
            }
          }
          // In voice-only mode, we don't stop listening based on silence
        }
  
        // Continuously update the volume levels on the next animation frame
        if (!isVolumeMeterRunningRef.current) {
          return; // Stop the loop
        }

  
    
        requestAnimationFrame(updateVolume);
      };
  
      // Start the volume meter
      updateVolume();
    }
  };


  const stopVolumeMeter = () => {
    if (speakingTimeoutRef.current) {
      clearTimeout(speakingTimeoutRef.current);
      speakingTimeoutRef.current = null;
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setVolume(0);
    setIsSpeaking(false);

    isVolumeMeterRunningRef.current = false;
  };

  // Helper function to convert base64 to Blob
    const base64ToBlob = (base64: string, mimeType: string): Blob => {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      //for debugging
      if (byteCharacters.length % 2 !== 0) {
        throw new Error('PCM chunk byte length must be a multiple of 2.');
      }

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mimeType });
    };

    function pcmToWav(pcmData: Uint8Array, sampleRate: number = 16000, numChannels: number = 1): Uint8Array {

      if (pcmData.length % 2 !== 0) {
        console.error('PCM data length is not a multiple of 2. Audio may be corrupted.');
        throw new Error('PCM data length must be a multiple of 2.');
    }
      const byteRate = sampleRate * numChannels * 2; // 16-bit = 2 bytes per sample
      const blockAlign = numChannels * 2;
      const wavHeader = new ArrayBuffer(44);
      const view = new DataView(wavHeader);
    
      // RIFF chunk descriptor
      writeString(view, 0, 'RIFF'); // ChunkID
      view.setUint32(4, 36 + pcmData.length, true); // ChunkSize
      writeString(view, 8, 'WAVE'); // Format
    
      // "fmt " sub-chunk
      writeString(view, 12, 'fmt ');
      view.setUint32(16, 16, true); // Subchunk1Size (PCM)
      view.setUint16(20, 1, true);  // AudioFormat (PCM = 1)
      view.setUint16(22, numChannels, true); // NumChannels
      view.setUint32(24, sampleRate, true);  // SampleRate
      view.setUint32(28, byteRate, true);    // ByteRate
      view.setUint16(32, blockAlign, true);  // BlockAlign
      view.setUint16(34, 16, true); // BitsPerSample
    
      // "data" sub-chunk
      writeString(view, 36, 'data');
      view.setUint32(40, pcmData.length, true);
    
      function writeString(view: DataView, offset: number, str: string) {
        for (let i = 0; i < str.length; i++) {
          view.setUint8(offset + i, str.charCodeAt(i));
        }
      }
    
      // Combine header and PCM data
      const wavBuffer = new Uint8Array(44 + pcmData.length);
      wavBuffer.set(new Uint8Array(wavHeader), 0);
      wavBuffer.set(pcmData, 44);
      return wavBuffer;
    }

    const messageQueue: string[] = []; // Queue to hold messages to be spoken
    let isProcessingQueue = false;



  const speak_basic = (texts: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!('speechSynthesis' in window)) {
        alert('Your browser does not support speech synthesis.');
        reject('Speech synthesis not supported');
        return;
      }


      // if (isBotSpeakingRef) {
      //   stopListening(); // Stop listening if currently listening to avoid recording its own speech
      // }
      if (isBotSpeakingRef.current) {
        stopListening();
      }
      // setIsBotSpeaking(true);
      setIsBotSpeakingAndRef(true);
      //console.log('set is bot speaking')

      const selectVoiceAndSpeak = (voices: SpeechSynthesisVoice[]) => {
        const selectedLanguage = localStorage.getItem('language') || 'english';
        const langCode = selectedLanguage.toLowerCase() === 'chinese' ? 'zh-CN' : 'en-US';



        let currentUtteranceIndex = 0;
        const totalUtterances = texts.length;

        const speakNext = async () => {
          if (currentUtteranceIndex < totalUtterances) {
            const text = texts[currentUtteranceIndex];
  
            // Call the backend to get audio content
           // console.log('this is the selected voice 2', selectedVoice)
           
            const voiceToUse = selectedVoice || 'en-US-Standard-C'; 
            //console.log("this is the voice to use", voiceToUse)
            const base64Audio = await getAudioFromBackendBasic(text, langCode, voiceToUse);

            // Convert the base64 string to a Blob
            const audioBlob = base64ToBlob(base64Audio, 'audio/mp3');

            // Create a URL for the Blob
            const audioUrl = URL.createObjectURL(audioBlob);
  
            // Create an Audio object
            const audio = new Audio(audioUrl);
  
            // Handle when the audio ends
            audio.onended = () => {
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(audioUrl);
              handleNextUtterance();
            };
  
            // Handle errors in audio playback
            audio.onerror = (error) => {
              console.error('Audio playback error: ', error);
              // Revoke the object URL to free up memory
              URL.revokeObjectURL(audioUrl);
              handleNextUtterance();
            };
  
            // Play the audio
            audio.play();
          } else {
            // All texts have been spoken
            setIsBotSpeaking(false);
            resolve();
          }
        };
  

         // Function to handle the next utterance
         const handleNextUtterance = () => {
          currentUtteranceIndex++;
          if (currentUtteranceIndex < totalUtterances) {
            speakNext();
          } else {
            // setIsBotSpeaking(false);
            setIsBotSpeakingAndRef(false);
            setIsVoiceModeEnabledAndRef(true);
            setIsSilenceDetectionEnabledAndRef(true);
            setIsListening(true);
    


           // console.log('voice enbaled', isVoiceModeEnabledRef.current)
           // console.log('isSilenceDetectionEnabledRef.current:', isSilenceDetectionEnabledRef.current);
     
                if (isVoiceModeEnabledRef.current) {
                    if (isSilenceDetectionEnabledRef.current) {
                      // Headphone mode
                     // console.log('start listening in handle next utterance', isListeningOriginal)
                      startListening();
                    } else {
                      // Voice-only mode
                      startListeningVoiceOnly();
                    }
                  }


                  resolve();
                }
        
        };
         

        speakNext();

    
      };

      const handleVoicesChanged = () => {
        const voices = window.speechSynthesis.getVoices();
        selectVoiceAndSpeak(voices);
        window.speechSynthesis.removeEventListener('voiceschanged', handleVoicesChanged);
      };

      // Ensure voices are loaded before speaking
      if (window.speechSynthesis.getVoices().length === 0) {
        window.speechSynthesis.addEventListener('voiceschanged', handleVoicesChanged);
      } else {
        const voices = window.speechSynthesis.getVoices();
        selectVoiceAndSpeak(voices);
      }
    });
  };



const speak = (texts: string[]): Promise<void> => {


  return new Promise((resolve, reject) => {
    if (!('speechSynthesis' in window)) {
      alert('Your browser does not support speech synthesis.');
      return reject('Speech synthesis not supported');
    }

    // Add new messages to the queue
    messageQueue.push(...texts);

    // Process the queue if not already processing
    if (!isProcessingQueue) {
      processQueue(resolve);
    } else {
    //  console.log('Already processing queue. Messages will be processed in order.');
      resolve();
    }
  });
};

const processQueue = async (resolve: () => void) => {
 // console.log("Processing queue. Current queue length:", messageQueue.length);

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const sampleRate = 24000; // Adjust based on actual sample rate

  while (messageQueue.length > 0) {
    const text = messageQueue.shift(); // Get the next message
    if (!text) continue;

    const selectedLanguage = localStorage.getItem('language') || 'english';
    const langCode = selectedLanguage.toLowerCase() === 'chinese' ? 'zh-CN' : 'en-US';

    setIsBotSpeakingAndRef(true);
    //console.log('Bot speaking...');


    // const wavData = pcmToWav(combined, sampleRate, 1);

    try {
      let allChunks: Uint8Array[] = [];

      const voiceToUse = selectedVoice; 
      await getAudioFromBackend(text, voiceToUse, (chunk: Uint8Array) => {
        allChunks.push(chunk);
      });
  
      if (allChunks.length === 0) {
        console.error('No audio chunks received. Skipping processing.');
        continue; // Skip processing if no chunks are available
      }
      
  
      const totalLength = allChunks.reduce((sum, c) => sum + c.length, 0);
      
      const combined = new Uint8Array(totalLength);
  
      let offset = 0;
      for (const c of allChunks) {
        combined.set(c, offset);
        offset += c.length;
      }
  
     // console.log('PCM Data Length:', combined.length);
      //console.log('PCM Data:', combined);
  
      if (combined.length === 0) {
        console.error('PCM Data Length is 0. Skipping message.');
        continue; // Skip processing if PCM data is empty
      }

     
      
      const wavData = pcmToWav(combined, sampleRate, 1);
      const buffer = await audioContext.decodeAudioData(wavData.buffer);
      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);



      // Mark bot as speaking


      await new Promise<void>((innerResolve) => {
        source.onended = () => {
          innerResolve();
        };
        source.start();
      });

      //console.log('Bot finished speaking.');

      //  // Mark bot as no longer speaking
      //  setIsBotSpeakingAndRef(false);
    } catch (error) {
      setIsBotSpeakingAndRef(false);
      console.error('Audio decoding error:', error);
    }finally {
      // Ensure that isBotSpeaking is set to false after processing each message
      setIsBotSpeakingAndRef(false);
    }
  }

  //console.log('All messages processed. Checking if listening needs to restart.');


  // setIsBotSpeakingAndRef(false);
   // Restart listening only if voice mode is enabled and the bot is not speaking
   if (isVoiceModeEnabledRef.current && !isBotSpeakingRef.current) {
   // startListening();
    if (isSilenceDetectionEnabledRef.current) {
        await startListening();
    } else {
        await startListeningVoiceOnly();
    }
} else {
    console.log('Not restarting listening: Bot is still speaking or voice mode is disabled.');
}


   audioContext.close();
  isProcessingQueue = false;
  resolve();
};


    
 

  return {
    isListening,
    startListening,
    stopListening,
    speak,
    speak_basic,
    isSpeaking,
    volume,
    startVoiceMode,
    stopVoiceMode,
    startVoiceOnlyMode,
    isVoiceModeEnabled,
    transcribedText,
    setTranscribedText,
    startListeningVoiceOnly,
    isVoiceModeEnabledRef,
    currentModeRef,
    retrieveSelectedVoice,
    selectedVoice,
    isBotSpeakingRef,

  };
};
