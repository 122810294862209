import React, { useState} from "react";
import Header from "./Header";
import LoginForm from "./LoginForm";
import "../css/loginstyle.scss";
import FrontPageFooter from "./FrontPageFooter";


const LoginPage = () => {

  return (
    
      <div className="header">
        <Header />
        <div className="login-page-container">
        <div className="info-message">
            <p>
              <strong>Limited Features on Mobile:</strong> The mobile web version of
              our chatbot does not include full features. For a better
              experience, please visit{" "}
              <a
                href="https://www.psyfy.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.psyfy.ai
              </a>{" "}
              on a PC or download our iOS app from the App Store.
            </p>
           
          </div>
        <div className="form-and-chat">
          <LoginForm />
        </div>
      </div>
      <div className="footer-container">
        <FrontPageFooter />
      </div>
    </div>
  );
};

export default LoginPage;
