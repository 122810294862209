import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../assets/logo_M.png";

const FrontPageFooter = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="front-footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={CompanyLogo} alt="Company Logo" />
        </div>
        <div className="footer-column">
          <Link to="/privacy" className="footer-link" onClick={scrollToTop}>
            Privacy Policy
          </Link>
          <Link to="/terms" className="footer-link" onClick={scrollToTop}>
            T&C
          </Link>
        </div>
        <div className="footer-column">
          <Link to="/" className="footer-link" onClick={scrollToTop}>
            Homepage
          </Link>
          {/* <Link to="/emotionReg" className="footer-link">
            Resource Bank
          </Link> */}
          <Link to="/research" className="footer-link" onClick={scrollToTop}>
            Research
          </Link>
        </div>
  
      </div>
      <div className="footer-bottom">
        <p>© 2024 Psyfy Inc. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default FrontPageFooter;
